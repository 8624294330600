@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.bg-gradient{
  background: linear-gradient(to bottom, #06060C 0%, #000423 53%, #0D0023 67%);
}
.footer-bg{
  background-image: url('./images/footeraura.png');
}
body{
  color: white;
}
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
